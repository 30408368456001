var doc = {};
var win = {};
var app = {};

app = {

	slider: function() {
		$('.project-content').each(function() {
			var _this = $(this);

			var next = function() {
				_this.find('li').stop();
				var _current = _this.find('li:visible'),
					_next = _current.next().length ? _current.next() : _current.siblings(':first');

				_current.fadeOut();
				_next.fadeIn();
			}

			var prev = function() {
				_this.find('li').stop();
				var _current = _this.find('li:visible'),
					_next = _current.prev().length ? _current.prev() : _current.siblings(':last');

				_current.fadeOut();
				_next.fadeIn();
			}
			_this.closest('.project').find('.navigation .prev').on('click', function(e) {
				e.preventDefault();
				prev();
			});
			_this.closest('.project').find('.navigation .next').on('click', function(e) {
				e.preventDefault();
				next();
			});
		});
	},
	handleMenu: function() {
		$('.menu-trigger').on('click', function(e) {
			e.preventDefault();
			$('.main-menu-board').fadeIn('fast', function() {
			    app.centerMenu();
			});
		})
		$('.close-menu').on('click', function(e) {
			e.preventDefault();
			$('.main-menu-board').fadeOut('fast');
		})
	},
	handleFilters: function() {
		$('.filter-trigger').on('click', function(e) {
			e.preventDefault();
			if($('.filters-board').is(':visible')) {
				$('.filters-board').fadeOut('fast', function() {
				});

			} else {
				$('.filters-board').fadeIn('fast', function() {

				});
			}
		})
	},
	centerMenu: function() {
		var _menu_height = parseInt($('.main-menu nav').height());
		var _popup_height = parseInt($(window).height());
		var _x_height = parseInt($('.main-menu-board .close-menu').height());

		var _padding = Math.floor((_popup_height - _menu_height) / 2) - _x_height;

		$('.main-menu').css({'padding-top': _padding});
	},
	moveMarble: function(where) {
		var _controller_width = parseInt($('.controller').width()),
			_total_slides = $('.nivo-control').length - 1,
			_marble_width = parseInt($('.marble').width());

		$('.marble').css({'left': ((_controller_width / _total_slides) * where) - (_marble_width * where/_total_slides) + 1 });
	},
	filters: function() {
		$('.filters a').on('click', function(e) {
			e.preventDefault();
			$(this).toggleClass('active');

		});
		$('.go').on('click', function(e) {
			e.preventDefault();

			var _type = '';
			$('.filters a.active[data-filter="type"]').each(function() {
				_type = _type + ($(this).data('value')) + ',';
			});
			var _time = '';
			$('.filters a.active[data-filter="time"]').each(function() {
				_time = _time + ($(this).data('value')) + ',';
			});
			var _location = '';
			$('.filters a.active[data-filter="location"]').each(function() {
				_location = _location + ($(this).data('value')) + ',';
			});
			var _query = "?type=" + app.trim(_type) + "&location=" + app.trim(_location) + "&time=" + app.trim(_time);
			window.location.href="/" + _query;

		});
	},
	trim: function(x) {
	    return x.replace(/^,+|,+$/gm,'');
	}

}

doc.init = function () {

}
win.init = function () {
    // run everything
    app.slider();
    app.filters();
    app.handleMenu();
    app.handleFilters();
    app.centerMenu();
    $('.slider').nivoSlider({
    	'effect':'fade',
    	controlNavThumbs: true,
    	slices: 1,
    	'manualAdvance': true,
    	'prevText': '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>_sageata_stanga_iconite_web_20_x_20_px</title><g><rect x="8.59" y="9" width="11.41" height="2.01"/><path d="M9.82,0l1.35,1.45L2.4,10l8.77,8.55L9.82,20,0.3,10.73a1,1,0,0,1,0-1.45Z" transform="translate(0 0)"/></g></svg>',
    	'nextText': '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>_sageata_dreapta_iconite_web_20_x_20_px</title><g>  <rect y="9" width="11.41" height="2.01"/><path d="M11.18,20L9.84,18.55,18.6,10,9.84,1.45,11.18,0,20.7,9.27a1,1,0,0,1,0,1.45Z" transform="translate(-1)"/></g></svg>',
    	afterChange: function() {
    		app.moveMarble($('.slider').data('nivo:vars').currentSlide);
    	}
    });
}



$(document).ready(doc.init);
$(window).load(win.init);
$(window).resize(function() {
    app.centerMenu();

});